const Data = [
  {
    id: "1",
    path: "/questions",
    path_name: "Question",
  },
  {
    id: "2",
    path: "/tags",
    path_name: "Tags",
  },
  {
    id: "3",
    path: "/contributors",
    path_name: "Contributors",
  },
];

export default Data;
