import React from "react";
import QuestionPage from "../QuestionPage/QuestionPage";

const LandingPage = () => {
  return (
    <div> 
      <QuestionPage />
    </div>
  );
};

export default LandingPage;